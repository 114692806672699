
import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';
import { Form } from 'vee-validate';
import { useProgrammatic } from '@oruga-ui/oruga-next';
import Platforms from '@/components/Platforms.vue';

export default defineComponent({
    components: { Platforms },
    mixins: [Base],
    emits: ['reload'],
    data() {
        return {
            params: {
                platform: undefined,
            },
            link: null,
        };
    },
    methods: {
        exportItems(): void {
            this.post('objects/export', {
                ...this.params,
                platform_id: this.params.platform ? this.params.platform.id : null,
            })
                .then(({ data }) => {
                    this.link = data.filename;
                })
                .catch(() => {
                })
                .finally(() => {
                });
        },
    },
});
